import React from "react";
import ManagementItem from "./ManagementItem";
import '../../.templates/css/portlets/managementBox/ManagementBox.css';

function ManagementBox(params) {
    let colorBack = ''
    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background']
    }
    let itemLength = 'col-box-0'
    if (params.block['en'].hasOwnProperty('Item')) {
        itemLength = 'col-box-' + Object.keys(params.block['en']['Item']).length
    }


    let foo = params.block.en.Email && `mailto:${params.block.en.Email}`;
    let itemContainer = 'management-list d-flex f-wrap mt-37 ' + itemLength;;
    let innerSection = 'management-box';
    let innerContainer = 'container';
    if (params.block['en']['InnerBackground'] !== '') {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    if (itemLength == 'col-box-2' || itemLength == 'col-box-1') {
        return (
            <>
                <section className={innerSection}>
                    <div className="wrapper">
                        <div className={innerContainer}>
                            <div>
                                <div className="upper-title d-flex f-row jc-between">
                                    <div className="title-container custom-small">
                                        <h1>{params.block['en']['Title']}</h1>
                                    </div>
                                    <div className="d-flex">
                                        <a className="js-end mb-6" href={foo}>{params.block['en']['Email']}</a>
                                    </div>
                                </div>
                                <div className={itemContainer}>
                                    {Object.keys(params.block['en']['Item']).map((item, i) => (
                                        <ManagementItem
                                            image={params.block['en']['Item'][item]['Image']['path']}
                                            altText={params.block['en']['Item'][item]['AltText']}
                                            Text={params.block['en']['Item'][item]['Text']}
                                            underText={params.block['en']['Item'][item]['underText']}
                                            Item={params.block['en']['Item'][item]}
                                            LinkName={params.block['en']['Item'][item]['LinkName']}
                                            textAbout={params.block['en']['Item'][item]['textAbout']}
                                            vartosc={itemLength}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
    else {
        return (
            <>
                <section className={innerSection}>
                    <div className="wrapper">
                        <div className={innerContainer}>
                            <div>
                                <div className="upper-title d-flex f-row jc-between">
                                    <div className="title-container custom-small">
                                        <h1>{params.block['en']['Title']}</h1>
                                    </div>
                                    <div className="d-flex">
                                        <a className="js-end mb-6" href="mailto:{params.block['en']['Email']}">{params.block['en']['Email']}</a>
                                    </div>
                                </div>
                                <div className='management-list d-flex f-wrap mt-37 col-set'>
                                    {Object.keys(params.block['en']['Item']).map((item, i) => (
                                        <ManagementItem
                                            image={params.block['en']['Item'][item]['Image']['path']}
                                            altText={params.block['en']['Item'][item]['AltText']}
                                            Text={params.block['en']['Item'][item]['Text']}
                                            underText={params.block['en']['Item'][item]['underText']}
                                            Link={params.block['en']['Item'][item]['Link']}
                                            LinkName={params.block['en']['Item'][item]['LinkName']}
                                            textAbout={params.block['en']['Item'][item]['textAbout']}
                                            vartosc={itemLength}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ManagementBox;